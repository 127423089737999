import { AsfUiNotification } from '@ui/types'

interface AsfNotifications {
  notifications: AsfUiNotification[]
}

let state: AsfNotifications

export const useUiNotification = () => {
  state = state || reactive<AsfNotifications>({ notifications: [] })

  const findIndex = (notificationId: string) => state.notifications.findIndex((item) => item.id === notificationId)

  const send = (id: string, options: AsfUiNotification) => {
    const dismiss = () => {
      const index = findIndex(id)

      if (index !== -1) {
        state.notifications.splice(index, 1)
        EventBus.emit('notification:dismiss', id, options)
      }
    }

    if (findIndex(id) === -1) {
      const newNotification: AsfUiNotification = { ...options, id, dismiss }
      state.notifications.splice(0, state.notifications.length)
      nextTick(() => {
        setTimeout(() => state.notifications.push(newNotification))
      })
      EventBus.emit('notification:send', id, options)
    }
  }

  return { send, notifications: computed(() => state.notifications) }
}
